import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import styled from 'styled-components';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import GlobalStyle from './styles/globalStyles';

import './index.css';
import './i18n.js';

import { Toaster } from 'react-hot-toast';
import Navigation from './navigations';
import ErrorBoundary from 'components/ErrorBoundary';
import useDisableInspect from 'hooks/useDisableInspect';

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

function App() {
  useDisableInspect()
  return (
    <GoogleOAuthProvider  clientId="1077400236932-874ecnnjr94k3kiermh4pkfbd5bdqisi.apps.googleusercontent.com">
      <Router>
        <GlobalStyle />
        <AppContainer>
          <Headers />
          <ErrorBoundary>
          <MainContent>
            <Navigation />
          </MainContent>
          </ErrorBoundary>
          <Footers />
          <Toaster />
        </AppContainer>
      </Router>
    </GoogleOAuthProvider>
  );
}

function Headers() {
  const location = useLocation();
  const hideHeaderPaths = [
    '/login',
    '/register',
    '/dashboard',
    '/reset-password',
    '/confirm-email/',
  ];

  const shouldHideHeader = hideHeaderPaths.some((path) =>
    location.pathname.startsWith(path),
  );

  return shouldHideHeader ? null : <Header />;
}

function Footers() {
  const location = useLocation();
  const hideFooterPaths = [
    '/login',
    '/register',
    '/dashboard',
    '/reset-password',
    '/confirm-email/',
  ];

  const shouldHideFooter = hideFooterPaths.some((path) =>
    location.pathname.startsWith(path),
  );

  return shouldHideFooter ? null : <Footer />;
}

export default App;
