import { useState } from 'react';
import toast from 'react-hot-toast';
import queryClient from 'config/query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { commonUtils } from 'utils';
import { CACHE_KEYS } from 'common';
import CustomTable from './CustomTable';
import useMuseumStore from '../../store/museum';
import ConfirmationModal from './ConfirmationModal';
import { adminService, userService } from 'services';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import {
  jsonToFormData,
  showMutationError,
  showSuccessMessage,
} from 'utils/common';
import SidebarModel from './SidebarModel';
import { useModal } from 'hooks/useModal';
import BaseArtWorkForm from './ArtWork/BaseForm';

type MuseumDashboardProps = {
  data: any;
  search: string;
  isLoading: boolean;
  totalPages: number;
  paginationModel: { page: number };
  setSearch: (value: string) => void;
  handlePageChange: (page: number) => void;
};

const StatusBadge = ({ isVerified }: { isVerified: boolean }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`text-center px-2 py-1  whitespace-nowrap rounded-full ${
        isVerified ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
      }`}
    >
      {t(isVerified ? 'verified' : 'notVerified')}
    </div>
  );
};

const defaultValues = {
  name: '',
  city: '',
  email: '',
  phone: '',
  website: '',
  address: '',
  country: '',
  telegram: '',
  facebook: '',
  password: '',
  instagram: '',
  description: '',
  openingHours: '',
  profilePicture: null,
  backgroundPicture: null,
};

const MuseumsDashboard = ({
  data,
  totalPages,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  isLoading: isMuseumLoading,
  setSearch: setSearchQuery,
}: MuseumDashboardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { museumError: error } = useMuseumStore();
  const { modalState, modalStateHandler } = useModal();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMuseum, setSelectedMuseum] = useState<Record<
    string,
    any
  > | null>(null);

  // create artwork
  const { isPending: loadingMuseums, mutateAsync: createMuseums } = useMutation(
    {
      mutationFn: (formdata: any) => adminService.createMuseums(formdata),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.MUSEUMS],
        });
      },
      onSettled: () => {
        modalStateHandler('create', false);
      },
    },
  );

  const { mutateAsync: deleteMuseum } = useMutation({
    mutationFn: (id: string) => adminService.deleteMuseumById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.MUSEUMS],
      });
      toast.success('Museum deleted successfully');
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const { mutateAsync: verifyMuseums } = useMutation({
    mutationFn: (ids: any) => adminService.verifyMuseums(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.MUSEUMS],
      });
    },
  });

  const batchVerify = async (ids: any, cb?: any) => {
    try {
      if (!ids.length) return;
      await verifyMuseums(ids);
      showSuccessMessage(t);

      cb && cb?.();
    } catch (error) {
      showMutationError(error);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedMuseum) {
      deleteMuseum(selectedMuseum.id);
      setModalOpen(false);
    }
  };

  const handleView = (museum: any) => {
    if (!museum.id) return;
    batchVerify([museum.id]);
  };

  const handleDelete = (museum: any) => {
    setModalOpen(true);
    setSelectedMuseum(museum);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const columns = [
    {
      Header: 'museumfields.profilePicture',
      accessor: 'profilePictureThumbnail',
      Type: 'Image',
    },
    { Header: 'name', accessor: 'name' },
    { Header: 'phone', accessor: 'phone' },
    { Header: 'email', accessor: 'email' },
    { Header: 'address', accessor: 'address' },
    { Header: 'city', accessor: 'city' },
    { Header: 'country', accessor: 'country' },
    { Header: 'website', accessor: 'website' },
    { Header: 'museumfields.openingHours', accessor: 'openingHours' },
    { Header: 'description', accessor: 'description' },
    { Header: 'facebook', accessor: 'facebook' },
    { Header: 'instagram', accessor: 'instagram' },
    { Header: 'telegram', accessor: 'telegram' },
    {
      Header: 'event.fields.status',
      accessor: 'isVerified',
      body: ({ row }: { row: any }) => (
        <StatusBadge isVerified={row.isVerified} />
      ),
    },
  ];

  const hanldeView = (museum: any) => {
    if (!museum.id) return;
    navigate('/museum-detail/' + museum.id);
  };

  const actions = [
    { title: 'View', onClick: hanldeView },
    { title: 'Delete', onClick: handleDelete },
    { title: 'Verify', onClick: handleView, id: 'approve' },
  ];

  const hanldeSubmit = async (values: any) => {
    if (loadingMuseums) {
      return;
    }

    values = commonUtils.parseFromPayload(values);
    const formData = jsonToFormData(values);
    await createMuseums(formData);
  };

  return (
    <div>
      <CustomTable
        error={error}
        action={actions}
        title="Museums"
        columns={columns}
        btnTxt="add_museum"
        data={data?.museums || []}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        totalPages={totalPages}
        handleVerify={batchVerify}
        loading={isMuseumLoading}
        openForm={() => modalStateHandler('create', true)}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setModalOpen(false)}
          title={`Do you want to delete ${
            selectedMuseum?.name || 'this'
          } Museum?`}
        />
      )}

      <SidebarModel
        title="add_museum"
        onClose={() => modalStateHandler('create', false)}
        isOpen={modalState.create}
      >
        <BaseArtWorkForm
          isMuseum={true}
          submitHandler={hanldeSubmit}
          defaultValues={defaultValues}
        />
      </SidebarModel>
    </div>
  );
};

export default withPaginatedQuery(MuseumsDashboard, {
  queryFn: userService.getMuseums,
  queryKey: [CACHE_KEYS.MUSEUMS],
});
