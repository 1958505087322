import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID!);

const usePageAnalytics = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: document.title,
            search: location.search
        });
    }, [location?.pathname]);
};

export default usePageAnalytics