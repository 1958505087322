import { AxiosError } from 'axios';
import { TRANSLATION_KEYS } from 'common';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import toast from 'react-hot-toast';

type LabelValueObject = {
  label: string;
  value: string;
};

type Payload = Record<string, any>; // Define a generic type for the payload

export function parseFromPayload<T extends Payload>(payload: T): T {
  const parseValue = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map((item) => parseValue(item));
    }

    if (isLabelValueObject(value)) {
      return value.value;
    }

    if (
      value &&
      typeof value === 'object' &&
      'value' in value &&
      value !== null &&
      !(value instanceof File)
    ) {
      const parsedObject: Record<string, any> = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          parsedObject[key] = parseValue(value[key]);
        }
      }
      return parsedObject;
    }

    return value;
  };

  const parsedPayload: any = {};
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      parsedPayload[key] = parseValue(payload[key]);
    }
  }

  return parsedPayload as T;
}

// Helper function to check if an object is of type LabelValueObject
function isLabelValueObject(obj: any): obj is LabelValueObject {
  return obj && typeof obj === 'object' && 'label' in obj && 'value' in obj;
}

export function getSelectOptionHelper(t: TFunction) {
  const keys = TRANSLATION_KEYS(t);
  return (v: string) => {
    const label = keys[v as keyof typeof keys];
    return label
      ? {
          label,
          value: v,
        }
      : '';
  };
}

export function strToOptionsHelper(arr: string[] | string) {
  arr = Array.isArray(arr) ? arr : arr.split(',');
  return arr?.map((v) => ({ label: v, value: v })) ?? [];
}

export function showMutationError(error: any) {
  let message = error;
  if (error instanceof Error) {
    message = error.message;
  }

  if (error instanceof AxiosError) {
    message = error.response?.data?.message;
  }

  toast.error(message?.slice(0, 100));
}

export const showSuccessMessage = (t: TFunction) => {
  toast.success(t('actionMessages.success'));
};

export const jsonToFormData = (json: any) => {
  const formData = new FormData();
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      if (key == 'tags') {
        formData.append(key, json[key].join(','));
      } else {
        formData.append(key, json[key]);
      }
    }
  }
  return formData;
};

export const formatDate = (updatedAt: any) => {
  const date = new Date(updatedAt);

  const readableTime = date?.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
  return readableTime;
};

export const formatWithoutHours = (updatedAt: any) => {
  const date = new Date(updatedAt);

  const readableTime = date?.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return readableTime;
};


export function formatTime(timeString:any, format = "hh:mm") {
  // Attach today's date to make it a valid DateTime
  const fullDateTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${timeString}`, "YYYY-MM-DD HH:mm:ss");

  // Check if the date is valid
  if (!fullDateTime.isValid()) {
      return "Invalid Time Format";
  }

  // Return the formatted time
  return fullDateTime.format(format);
}


export const pick = (obj: any, keys: string[]) => {
  const result: any = {};
  keys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const yymmddFormat = (date: any) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const deleteFalsyValues = <T extends {}>(obj: T) => {
  const result: Partial<T> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const getUserDetails = (result: Record<string, any>) => {
  return {
    name:
      result?.Artist?.pseudonym ||
      result?.Gallery?.name ||
      result?.Museum?.name ||
      '- -',
    phone: result?.Artist?.phone || result?.Gallery?.phone || '- -',
    profilePicture:
      result?.Artist?.profilePicture ||
      result?.Gallery?.profilePicture ||
      result?.Museum?.profilePicture ||
      '- -',
    address: result?.Artist?.address || result?.Gallery?.address,
    description:
      result?.Artist?.biography ||
      result?.Gallery?.description ||
      result?.Museum?.description ||
      '- -',
  };
};

export const getProfileLink = (
  accountType: 'artist' | 'gallery' | 'museum' | 'admin',
  id: string,
  data: Record<string, any> = {},
) => {
  if (accountType === 'artist' || data.Artist?.id) {
    return `/author-detail/${id}`;
  }
  if (accountType === 'gallery' || data.Gallery?.id) {
    return `/gallery-detail/${id}`;
  }
  if (accountType === 'museum' || data.Museum?.id) {
    return `/museum-detail/${id}`;
  }
};

export const isClickable = (accountType: string, id: string) => {
  return ['artist', 'gallery', 'muesum'].includes(accountType) && id;
};
