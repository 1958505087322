import { TFunction } from 'i18next';
import { IFormField } from './base.dto';
import { baseDto } from 'dto';

export const artworkInputs = ({
  t,
  isEdit,
}: {
  t: TFunction;
  isEdit?: boolean;
}) => {
  return [
    {
      name: 'title',
      label: t('artwork.fields.title'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('artwork.fields.description'),
      type: 'multiline',
      rows: 3,
      max: 2000,
    },
    {
      name: 'type',
      label: t('artwork.fields.type'),
      type: 'select',
      options: [
        {
          value: 'visualArts',
          label: t('artwork_visualArts'),
        },
        {
          value: 'decorativeAndAppliedArts',
          label: t('artwork_decorativeAndAppliedArts'),
        },
        {
          value: 'digitalArt',
          label: t('artwork_digitalArt'),
        },
        {
          value: 'performanceArt',
          label: t('artwork_performanceArt'),
        },
        {
          value: 'folkArt',
          label: t('artwork_folkArt'),
        },
      ],
    },
    {
      name: 'medium',
      label: t('artwork.fields.medium'),
      type: 'select',
      options: [
        {
          value: 'painting',
          label: t('artwork_painting'),
        },
        {
          value: 'graphics',
          label: t('artwork_graphics'),
        },
        {
          value: 'sculpture',
          label: t('artwork_sculpture'),
        },
        {
          value: 'photography',
          label: t('artwork_photography'),
        },
        {
          value: 'ceramics',
          label: t('artwork_ceramics'),
        },
        {
          value: 'jewelryArt',
          label: t('artwork_jewelryArt'),
        },
        {
          value: 'textiles',
          label: t('artwork_textiles'),
        },
        {
          value: 'furnitureArt',
          label: t('artwork_furnitureArt'),
        },
        {
          value: 'digitalArt',
          label: t('artwork_digitalArt'),
        },
        {
          value: 'threeDGraphics',
          label: t('artwork_threeDGraphics'), // Used "threeD" to keep the key camelCase friendly
        },
        {
          value: 'generativeArt',
          label: t('artwork_generativeArt'),
        },
        {
          value: 'installations',
          label: t('artwork_installations'),
        },
        {
          value: 'streetArt',
          label: t('artwork_streetArt'),
        },
        {
          value: 'crafts',
          label: t('artwork_crafts'),
        },
        {
          value: 'traditionalPatterns',
          label: t('artwork_traditionalPatterns'),
        },
      ],
    },
    {
      name: 'width',
      label: t('artwork.fields.width'),
      type: 'number',
    },
    {
      name: 'length',
      label: t('artwork.fields.length'),
      type: 'number',
    },
    {
      name: 'height',
      label: t('artwork.fields.height'),
      type: 'number',
      isVisible(values) {
        return !([
          'painting',
          'graphics',
          'photography',
          'textiles',
          'traditionalPatterns',
          'digitalArt',
          'streetArt',
        ].includes(values.medium?.value));
      },
    },
   
    // {
    //   name: 'genre',
    //   label: t('artwork.fields.genre'),
    //   type: 'select',
    //   options: [
    //     {
    //       value: 'all',
    //       label: t('artwork_all'),
    //     },
    //     {
    //       value: 'abstract',
    //       label: t('artwork_abstract'),
    //     },
    //     {
    //       value: 'surrealism',
    //       label: t('artwork_surrealism'),
    //     },
    //     {
    //       value: 'impressionism',
    //       label: t('artwork_impressionism'),
    //     },
    //     {
    //       value: 'expressionism',
    //       label: t('artwork_expressionism'),
    //     },
    //     {
    //       value: 'realism',
    //       label: t('artwork_realism'),
    //     },
    //     {
    //       value: 'hyperrealism',
    //       label: t('artwork_hyperrealism'),
    //     },
    //     {
    //       value: 'minimalism',
    //       label: t('artwork_minimalism'),
    //     },
    //     {
    //       value: 'popArt',
    //       label: t('artwork_popArt'),
    //     },
    //     {
    //       value: 'cubism',
    //       label: t('artwork_cubism'),
    //     },
    //     {
    //       value: 'futurism',
    //       label: t('artwork_futurism'),
    //     },
    //     {
    //       value: 'baroque',
    //       label: t('artwork_baroque'),
    //     },
    //     {
    //       value: 'renaissance',
    //       label: t('artwork_renaissance'),
    //     },
    //     {
    //       value: 'romanticism',
    //       label: t('artwork_romanticism'),
    //     },
    //     {
    //       value: 'conceptualArt',
    //       label: t('artwork_conceptualArt'),
    //     },
    //     {
    //       value: 'figurativeArt',
    //       label: t('artwork_figurativeArt'),
    //     },
    //     {
    //       value: 'landscape',
    //       label: t('artwork_landscape'),
    //     },
    //     {
    //       value: 'stillLife',
    //       label: t('artwork_stillLife'),
    //     },
    //     {
    //       value: 'portraiture',
    //       label: t('artwork_portraiture'),
    //     },
    //     {
    //       value: 'streetArt',
    //       label: t('artwork_streetArt'),
    //     },
    //     {
    //       value: 'digitalArt',
    //       label: t('artwork_digitalArt'),
    //     },
    //     {
    //       value: 'fantasyArt',
    //       label: t('artwork_fantasyArt'),
    //     },
    //     {
    //       value: 'naiveArt',
    //       label: t('artwork_naiveArt'),
    //     },
    //     {
    //       value: 'opArt',
    //       label: t('artwork_opArt'),
    //     },
    //     {
    //       value: 'artNouveau',
    //       label: t('artwork_artNouveau'),
    //     },
    //     {
    //       value: 'artDeco',
    //       label: t('artwork_artDeco'),
    //     },
    //     {
    //       value: 'photographyGenres',
    //       label: t('artwork_photographyGenres'),
    //     },
    //   ],
    // },
    {
      name: 'material',
      label: t('artwork.fields.material'),
      type: 'multi-select',
      selectAll: true,
      options: [
        { value: 'watercolor', label: t('artwork_watercolor') },
        { value: 'aquatint', label: t('artwork_aquatint') },
        { value: 'acrylic', label: t('artwork_acrylic') },
        { value: 'aluminum', label: t('artwork_aluminum') },
        { value: 'asphaltLacquer', label: t('artwork_asphaltLacquer') },
        { value: 'bakelite', label: t('artwork_bakelite') },
        { value: 'concrete', label: t('artwork_concrete') },
        { value: 'beads', label: t('artwork_beads') },
        { value: 'bronze', label: t('artwork_bronze') },
        { value: 'paper', label: t('artwork_paper') },
        { value: 'archesPaper', label: t('artwork_archesPaper') },
        { value: 'laidPaper', label: t('artwork_laidPaper') },
        { value: 'wovePaper', label: t('artwork_wovePaper') },
        { value: 'ragPaper', label: t('artwork_ragPaper') },
        { value: 'vinyl', label: t('artwork_vinyl') },
        { value: 'waxedPaper', label: t('artwork_waxedPaper') },
        { value: 'embroidery', label: t('artwork_embroidery') },
        { value: 'plaster', label: t('artwork_plaster') },
        { value: 'clay', label: t('artwork_clay') },
        { value: 'potteryClay', label: t('artwork_potteryClay') },
        { value: 'engraving', label: t('artwork_engraving') },
        { value: 'printmaking', label: t('artwork_printmaking') },
        { value: 'graphite', label: t('artwork_graphite') },
        { value: 'gouache', label: t('artwork_gouache') },
        { value: 'decoupage', label: t('artwork_decoupage') },
        { value: 'wood', label: t('artwork_wood') },
        { value: 'giclee', label: t('artwork_giclee') },
        { value: 'digraphy', label: t('artwork_digraphy') },
        { value: 'oak', label: t('artwork_oak') },
        { value: 'blownGlass', label: t('artwork_blownGlass') },
        { value: 'iron', label: t('artwork_iron') },
        { value: 'pearl', label: t('artwork_pearl') },
        { value: 'mirror', label: t('artwork_mirror') },
        { value: 'gold', label: t('artwork_gold') },
        { value: 'limestone', label: t('artwork_limestone') },
        { value: 'stone', label: t('artwork_stone') },
        { value: 'pencil', label: t('artwork_pencil') },
        { value: 'carbon', label: t('artwork_carbon') },
        { value: 'cardboard', label: t('artwork_cardboard') },
        { value: 'pressedCardboard', label: t('artwork_pressedCardboard') },
        { value: 'ceramics', label: t('artwork_ceramics') },
        { value: 'stoneware', label: t('artwork_stoneware') },
        { value: 'leather', label: t('artwork_leather') },
        { value: 'collage', label: t('artwork_collage') },
        { value: 'dye', label: t('artwork_dye') },
        { value: 'paint', label: t('artwork_paint') },
        { value: 'crystal', label: t('artwork_crystal') },
        { value: 'woodcut', label: t('artwork_woodcut') },
        { value: 'varnish', label: t('artwork_varnish') },
        { value: 'brass', label: t('artwork_brass') },
        { value: 'linen', label: t('artwork_linen') },
        { value: 'linocut', label: t('artwork_linocut') },
        { value: 'lithography', label: t('artwork_lithography') },
        { value: 'oil', label: t('artwork_oil') },
        { value: 'oilPaint', label: t('artwork_oilPaint') },
        { value: 'mahogany', label: t('artwork_mahogany') },
        { value: 'copper', label: t('artwork_copper') },
        { value: 'chalk', label: t('artwork_chalk') },
        { value: 'metal', label: t('artwork_metal') },
        {
          value: 'microcrystallineWax',
          label: t('artwork_microcrystallineWax'),
        },
        { value: 'monoprint', label: t('artwork_monoprint') },
        { value: 'monotype', label: t('artwork_monotype') },
        { value: 'monotypy', label: t('artwork_monotypy') },
        { value: 'marble', label: t('artwork_marble') },
        { value: 'stainlessSteel', label: t('artwork_stainlessSteel') },
        { value: 'thread', label: t('artwork_thread') },
        { value: 'upholstery', label: t('artwork_upholstery') },
        { value: 'walnut', label: t('artwork_walnut') },
        { value: 'etching', label: t('artwork_etching') },
        { value: 'panel', label: t('artwork_panel') },
        { value: 'pastel', label: t('artwork_pastel') },
        { value: 'patina', label: t('artwork_patina') },
        { value: 'foam', label: t('artwork_foam') },
        { value: 'parchment', label: t('artwork_parchment') },
        { value: 'inkjetPrinting', label: t('artwork_inkjetPrinting') },
        { value: 'pigment', label: t('artwork_pigment') },
        { value: 'plastic', label: t('artwork_plastic') },
        { value: 'platinum', label: t('artwork_platinum') },
        { value: 'plexiglass', label: t('artwork_plexiglass') },
        { value: 'wovenPaper', label: t('artwork_wovenPaper') },
        { value: 'polaroid', label: t('artwork_polaroid') },
        { value: 'polymerClay', label: t('artwork_polymerClay') },
        { value: 'polyurethane', label: t('artwork_polyurethane') },
        { value: 'powder', label: t('artwork_powder') },
        { value: 'wire', label: t('artwork_wire') },
        { value: 'rubber', label: t('artwork_rubber') },
        { value: 'embossedPaper', label: t('artwork_embossedPaper') },
        { value: 'ricePaper', label: t('artwork_ricePaper') },
        { value: 'silver', label: t('artwork_silver') },
        { value: 'silverGelatinPrint', label: t('artwork_silverGelatinPrint') },
        { value: 'syntheticTextile', label: t('artwork_syntheticTextile') },
        { value: 'mixedMedia', label: t('artwork_mixedMedia') },
        { value: 'resin', label: t('artwork_resin') },
        { value: 'sprayPaint', label: t('artwork_sprayPaint') },
        { value: 'steel', label: t('artwork_steel') },
        { value: 'glass', label: t('artwork_glass') },
        { value: 'fiberglass', label: t('artwork_fiberglass') },
        { value: 'drypoint', label: t('artwork_drypoint') },
        { value: 'teak', label: t('artwork_teak') },
        { value: 'fabric', label: t('artwork_fabric') },
        { value: 'screenPrinting', label: t('artwork_screenPrinting') },
        { value: 'charcoal', label: t('artwork_charcoal') },
        { value: 'porcelain', label: t('artwork_porcelain') },
        { value: 'felt', label: t('artwork_felt') },
        { value: 'foil', label: t('artwork_foil') },
        { value: 'photopolymer', label: t('artwork_photopolymer') },
        { value: 'cotton', label: t('artwork_cotton') },
        { value: 'canvas', label: t('artwork_canvas') },
        { value: 'artPaper', label: t('artwork_artPaper') },
        { value: 'colorPrinting', label: t('artwork_colorPrinting') },
        { value: 'cement', label: t('artwork_cement') },
        { value: 'digital', label: t('artwork_digital') },
        { value: 'ink', label: t('artwork_ink') },
        { value: 'blackClay', label: t('artwork_blackClay') },
        { value: 'blackInk', label: t('artwork_blackInk') },
        { value: 'silkscreenPrinting', label: t('artwork_silkscreenPrinting') },
        { value: 'wool', label: t('artwork_wool') },
        { value: 'silk', label: t('artwork_silk') },
        { value: 'elastomer', label: t('artwork_elastomer') },
        { value: 'enamel', label: t('artwork_enamel') },
        { value: 'epoxyResin', label: t('artwork_epoxyResin') },
      ],
    },
    {
      name: 'reproduction',
      label: t('artwork.fields.reproduction'),
      type: 'boolean',
    },
    {
      name: 'year',
      label: t('artwork.fields.year'),
      type: 'number',
    },
    {
      name: 'orientation',
      label: t('artwork.fields.orientation'),
      type: 'select',
      options: [
        { value: 'portrait', label: t('artwork_portrait') },
        { value: 'landscape', label: t('artwork_landscape') },
        { value: 'square', label: t('artwork_square') },
        { value: 'circular', label: t('artwork_circular') },
      ],
    },
    {
      name: 'availability',
      label: t('artwork.fields.availability'),
      type: 'select',
      options: [
        { value: 'culturalHeritage', label: t('artwork_culturalHeritage') },
        { value: 'exhibitionOnly', label: t('artwork_exhibitionOnly') },
        {
          value: 'availableForPurchase',
          label: t('artwork_availableForPurchase'),
        },
      ],
    },
    {
      name: 'price',
      label: t('artwork.fields.price'),
      type: 'number',
      isVisible(values) {
        return values?.availability?.value === 'availableForPurchase';
      },
    },
    {
      name: 'image',
      label: t('artwork.fields.image'),
      type: 'file',
      optional: isEdit,
    },
  ] satisfies IFormField[];
};

export const artworkSchema = (t: TFunction, isEdit?: boolean) => {
  return baseDto.generateDtoHelper({
    prefix: 'artwork',
    fields: artworkInputs({ t, isEdit }),
    t,
  });
};

export const artworkKeys = [
  'title',
  'description',
  'dimensions',
  'type',
  'medium',
  'orientation',
  'reproduction',
  'year',
  'financialStatus',
  'availability',
  'image',
  'price',
  'currency',
  'material',
  'width',
  'length',
  'height',
  'imageUrl',
  'imageThumbnailUrl',
];
