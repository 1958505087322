import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CACHE_KEYS } from 'common';
import queryClient from 'config/query';
import CustomTable from './CustomTable';
import SidebarModel from './SidebarModel';
import { useModal } from 'hooks/useModal';
import { commonUtils } from '../../utils';
import EditArtWork from './ArtWork/EditArtWork';
import { artworkInputs } from 'dto/artwork.dto';
import useArtworkStore from '../../store/artWorks';
import ConfirmationModal from './ConfirmationModal';
import CreateArtWork from './ArtWork/CreateArtWork';
import { useMutation } from '@tanstack/react-query';
import { adminService, userService } from 'services';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import {
  jsonToFormData,
  showMutationError,
  showSuccessMessage,
} from 'utils/common';
import StatusBadge from './UI/StatusBadge';

const ArtworkDashboard = ({
  data,
  isLoading,
  totalPages,
  totalResults,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { modalState, modalStateHandler } = useModal();
  const { loading, error } = useArtworkStore();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedArt, setSelectedArt] = useState(null);

  //api mutations
  // create artwork
  const { isPending: loadingcreateArtWork, mutateAsync: createArtWork } =
    useMutation({
      mutationFn: (formdata) => adminService.createArtWork(formdata),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.ARTWORKS],
        });
      },
      onSettled: () => {
        modalStateHandler('create', false);
      },
      onError: (res) => {
        Object.values(res?.response?.data?.details)?.forEach((err) =>
          toast.error(typeof err == 'string' ? err : 'something went wrong!'),
        );
      },
    });
  //batch verify
  const { isPending: loadingVerify, mutateAsync: verifyArtWorks } = useMutation(
    {
      mutationFn: (ids) => adminService.verifyArtWorks(ids),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.ARTWORKS],
        });
      },
    },
  );
  // delete artwork
  const { isPending: loadingDelete, mutateAsync: deleteArtwork } = useMutation({
    mutationFn: (id) => adminService.deleteArtWorkById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.ARTWORKS],
      });
    },
  });
  //update artwork
  const { isPending: loadingUpdate, mutateAsync: updateArtwork } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminService.updateArtWorkById(id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.ARTWORKS],
      });
    },
  });
  //toggle visibility
  const { isPending: loadingVisibility, mutateAsync: toggleVisibility } =
    useMutation({
      mutationFn: (id) => adminService.toggleArtWorkVisibility(id),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.ARTWORKS],
        });
      },
    });

  const confirmDelete = () => {
    setModalOpen(false);
    deleteArtwork(selectedArt.id);
  };

  const hanldeApprove = (artwork) => {
    if (!artwork.id) return;
    batchVerify([artwork.id]);
    if (!artwork.id) return;
    batchVerify([artwork.id]);
  };

  const handleUpdateVisibility = (artwork) => {
    try {
      const id = artwork?.id;
      if (!id) return;
      toggleVisibility(id);
      showSuccessMessage(t);
      if (!id) return;
      toggleVisibility(id);
      showSuccessMessage(t);
    } catch (error) {
      showMutationError(error);
      showMutationError(error);
    }
  };

  const hanldeDelte = (e) => {
    console.log('e', e);
    setModalOpen(true);
    setSelectedArt(e);
  };

  const batchVerify = async (ids, cb) => {
    try {
      if (!ids.length) return;
      await verifyArtWorks(ids);
      showSuccessMessage(t);

      cb && cb?.();
    } catch (error) {
      showMutationError(error);
      showMutationError(error);
    }
  };

  const hanldeView = (artwork) => {
    if (!artwork.id) return;
    navigate('/painting-detail/' + artwork.id);
  };

  const action = [
    {
      id: 'view',
      title: 'View',
      onClick: hanldeView,
      permission: 'view:dashboardStatistics',
    },
    {
      id: 'approve',
      title: 'Approve',
      onClick: hanldeApprove,
      permission: 'view:dashboardStatistics',
    },
    {
      id: 'edit',
      title: 'Edit',
      onClick: (values) => {
        setSelectedArt(values);
        modalStateHandler('edit', true);
      },
    },
    {
      id: 'visibility',
      title: 'visibility',
      onClick: handleUpdateVisibility,
      permission: 'view:dashboardStatistics',
    },
    {
      id: 'delete',
      title: 'Delete',
      onClick: hanldeDelte,
    },
  ];

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  // hooks
  const columns = useMemo(() => {
    return [
      {
        Header: t('artwork.columns.image'),
        accessor: 'imageUrl',
        Type: 'Image',
      },
      { Header: t('artwork.columns.title'), accessor: 'title' },
      { Header: t('artwork.columns.visibility'), accessor: 'visibility' },
      { Header: t('artwork.columns.price'), accessor: 'price' },
      { Header: t('artwork.columns.forSale'), accessor: 'financialStatus' },
      { Header: t('artwork.columns.genre'), accessor: 'genre' },
      {
        Header: t('artwork.columns.orientation'),
        accessor: 'orientation',
      },
      {
        Header: t('artwork.columns.medium'),
        accessor: 'medium',
      },
      {
        Header: t('artwork.columns.material'),
        accessor: 'material',
      },
      {
        Header: t('artwork.columns.dimensions'),
        accessor: 'dimensions',
        getter: (obj) => `${obj.width} x ${obj.length} ${obj.sizeUnit}`,
      },
      {
        Header: 'Status',
        accessor: 'isVerified',
        body: ({ row }) => <StatusBadge isVerified={row.isVerified} />,
      },
    ];
  }, [t]);

  const hanldeSubmit = async (values) => {
    if (loadingcreateArtWork) {
      return;
    }
    values = commonUtils.parseFromPayload(values);
    const formData = jsonToFormData(values);
    await createArtWork(formData);
    showSuccessMessage(t);

  };

  const filterFormValues = (formValues, fields) => {
    let obj = {};

    fields?.forEach((element) => {
      if (formValues[element?.name] !== undefined || formValues[element?.name] !== null) {
        obj[element.name] = formValues[element.name];
      }
    });
    return obj;
  };

  const hanldeUpdated = async (values) => {
    values = commonUtils.parseFromPayload(values);
    const inputs = artworkInputs({ t });

    const filteredValues = filterFormValues(values, inputs);
    try {
      let data = jsonToFormData(filteredValues);
      await updateArtwork({ id: selectedArt?.id, formData: data });
      showSuccessMessage(t);
    } catch (error) {
      showMutationError(error);
    }
    modalStateHandler('edit', false);
  };

  return (
    <div>
      <CustomTable
        title="artworks"
        error={error}
        action={action}
        columns={columns}
        openForm={() => modalStateHandler('create', true)}
        btnTxt="upload_artwork"
        totalPages={totalPages}
        searchQuery={searchQuery}
        data={data?.artworks ?? []}
        totalResults={totalResults}
        handleSearch={handleSearch}
        loading={
          loadingcreateArtWork ||
          loading ||
          isLoading ||
          loadingVerify ||
          loadingDelete ||
          loadingUpdate ||
          loadingVisibility
        }
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        handleVerify={batchVerify}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={confirmDelete}
          onCancel={() => setModalOpen(false)}
          title={`Do you want to delete ${selectedArt?.title} artist?`}
        />
      )}

      {modalState.create && (
        <SidebarModel
          title="uploadArtwork"
          onClose={() => modalStateHandler('create', false)}
          isOpen={modalState.create}
        >
          <CreateArtWork hanldeSubmit={hanldeSubmit} />
        </SidebarModel>
      )}

      {selectedArt && (
        <SidebarModel
          title="editArtwork"
          onClose={() => modalStateHandler('edit', false)}
          isOpen={modalState.edit}
        >
          <EditArtWork
            key={selectedArt.id}
            defaultValues={selectedArt}
            hanldeSubmit={hanldeUpdated}
            handleClose={() => modalStateHandler('edit', false)}
          />
        </SidebarModel>
      )}
    </div>
  );
};

export default withPaginatedQuery(ArtworkDashboard, {
  queryFn: userService.getArtwork,
  queryKey: [CACHE_KEYS.ARTWORKS],
});
