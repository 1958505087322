import { useEffect } from "react";

const useDisableInspect = () => {
  useEffect(() => {

    if (process.env.NODE_ENV === "development") {
        return;
    }
    // Disable right-click
    const disableContextMenu = (event:any) => event.preventDefault();

    // Disable DevTools shortcuts
    const disableShortcuts = (event:any) => {
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
        (event.ctrlKey && event.keyCode === 85) // Ctrl+U (View Source)
      ) {
        event.preventDefault();
      }
    };

    // Disable image dragging
    const disableImageDrag = (event:any) => event.preventDefault();

    // Add event listeners
    document.addEventListener("contextmenu", disableContextMenu);
    document.addEventListener("keydown", disableShortcuts);
    document.addEventListener("dragstart", disableImageDrag);

    return () => {
      // Cleanup event listeners when the component unmounts
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableShortcuts);
      document.removeEventListener("dragstart", disableImageDrag);
    };
  }, []);
};

export default useDisableInspect;
