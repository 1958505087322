import { getSelectOptionHelper, pick, strToOptionsHelper } from 'utils/common';
import BaseArtWorkForm from './BaseForm';
import { useTranslation } from 'react-i18next';
import { artworkKeys } from 'dto/artwork.dto';

function getDefaultValues(values, t) {
  const optionHelper = getSelectOptionHelper(t);
  let materialOptions = Array.isArray(values.material)
    ? values.material
    : values.material.split(',');
  materialOptions = materialOptions.map(optionHelper);
  
  values = pick(values, artworkKeys);


  const result = {
    ...values,
    financialStatus: optionHelper(values.financialStatus),
    genre: optionHelper(values.genre),
    orientation: optionHelper(values.orientation),
    material: materialOptions,
    availability: optionHelper(values.availability),
    visibility: optionHelper(values.visibility),
    medium: optionHelper(values.medium),
    image: values?.imageUrl ?? values?.imageThumbnailUrl,
  };

  return result;
}

const EditArtWork = ({ defaultValues, hanldeSubmit }) => {
  const { t } = useTranslation();
  return (
    <BaseArtWorkForm
      submitHandler={hanldeSubmit}
      defaultValues={getDefaultValues(defaultValues || {}, t)}
    />
  );
};

export default EditArtWork;
