import * as yup from 'yup';
import { TFunction } from 'i18next';

export type IFormOption = {
  label: string;
  value: string;
};

export function translateWithPlaceholders(
  template: string,
  replacements: Record<string, string | number>,
): string {
  const result = template.replace(
    /{{\s*([^}]+)\s*}}/g,
    (match, key) => String(replacements[key]) || match,
  );
  return result;
}

export const stringSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  max: number = 255,
  optional: boolean = false,
) => {
  let schema = yup.string();

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema.max(
    max,
    translateWithPlaceholders(t('formErrors.maxLength'), {
      field: t(`${fieldName}`),
      max,
    }),
  );
};

export const numberSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.number();

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field:t(`${fieldName}`),
      }),
    );
  }

  schema = schema
    .typeError(
      translateWithPlaceholders(t('formErrors.mustBeNumber'), {
        field: t(`${fieldName}`),
      }),
    )
    .min(
      0,
      translateWithPlaceholders(t('formErrors.positiveNumber'), {
        field: t(`${fieldName}`),
      }),
    );

  return schema;
};

export const booleanSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.boolean().default(false);

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema;
};

export const dateSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.date();

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema;
};

export const optionsSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .typeError(
      translateWithPlaceholders(t('formErrors.required'), {
        field:t(`${fieldName}`),
      }),
    );

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema;
};

export const multipleOptionsSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.array().of(optionsSchema(t, fieldName, prefix));

  if (!optional) {
    schema = schema
      .required(
        translateWithPlaceholders(t('formErrors.required'), {
          field: t(`${fieldName}`),
        }),
      )
      .min(1, t('formErrors.minOptions'));
  }

  return schema;
};

export const emailSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.string().email(
    translateWithPlaceholders(t('formErrors.email'), {
      field: t(`${fieldName}`),
    }),
  );

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema;
};

export const imageSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.mixed();

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  } else {
    // @ts-ignore
    schema = schema.optional().nullable();
  }

  return schema;
};

export const passwordSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.string().min(6, t('formErrors.passwordLength'));

  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }

  return schema;
};

export const mutiValueSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.array().of(optionsSchema(t, fieldName, prefix));

  if (!optional) {
    schema = schema
      .required(
        translateWithPlaceholders(t('formErrors.required'), {
          field: t(`${fieldName}`),
        }),
      )
      .min(
        1,
        translateWithPlaceholders(t('formErrors.required'), {
          field: t(`${fieldName}`),
        }),
      );
  }

  return schema;
};

export const dateRangeSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  optional: boolean = false,
) => {
  let schema = yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
  });
  if (!optional) {
    schema = schema.required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${fieldName}`),
      }),
    );
  }
  return schema;
};

export type FieldType =
  | 'text'
  | 'time'
  | 'multiline'
  | 'email'
  | 'password'
  | 'number'
  | 'date'
  | 'file'
  | 'boolean'
  | 'select'
  | 'location'
  | 'multi-select'
  | 'dateRange'
  | 'multi-value';

export type IFormField = {
  type: FieldType;
  name: string;
  label: string;
  options?: { value: string; label: string }[];
  max?: number;
  rows?: number;
  selectAll?: boolean;
  optional?: boolean;
  isVisible?:(values:any) => boolean
  customValidator?: (schema: yup.AnySchema) => yup.AnySchema;
};
export const generateDtoHelper = ({
  prefix,
  t,
  fields,
}: {
  prefix: string;
  t: TFunction;
  fields: IFormField[];
}) => {
  const dto = fields.reduce((prev, field) => {
    let dtoSchema;
    const { optional, customValidator } = field;

    switch (field.type) {
      case 'text':
      case 'multiline':
        dtoSchema = stringSchema(t, field.label, prefix, field.max, optional);
        break;
      case 'email':
        dtoSchema = emailSchema(t, field.label, prefix);
        break;
      case 'password':
        dtoSchema = passwordSchema(t, field.label, prefix);
        break;
      case 'number':
        dtoSchema = numberSchema(t, field.label, prefix, optional);
        break;
      case 'date':
        dtoSchema = dateSchema(t, field.label, prefix, optional);
        break;
      case 'file':
        dtoSchema = imageSchema(t, field.label, prefix, optional);
        break;
      case 'boolean':
        dtoSchema = booleanSchema(t, field.label, prefix, optional);
        break;
      case 'select':
        dtoSchema = optionsSchema(t, field.label, prefix, optional);
        break;
      case 'multi-value':
        dtoSchema = mutiValueSchema(t, field.label, prefix, optional);
        break;
      case 'multi-select':
        dtoSchema = multipleOptionsSchema(t, field.label, prefix, optional);
        break;
      case 'dateRange':
        dtoSchema = dateRangeSchema(t, field.label, prefix, optional);
        break;
      default:
        dtoSchema = stringSchema(t, field.label, prefix, field.max, optional);
        break;
    }

    // Apply custom validator if provided
    if (customValidator) {
      dtoSchema = customValidator(dtoSchema);
    }

    prev[field.name] = dtoSchema;
    return prev;
  }, {} as Record<string, yup.AnySchema>);

  return yup.object().shape(dto).required();
};
