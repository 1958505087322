import axios, { Axios, AxiosError } from 'axios';
import toast from 'react-hot-toast';

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || 'http://localhost:3001';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['ngrok-skip-browser-warning'] = '69420';
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle any request errors here
    return Promise.reject(error);
  },
);

//show error on toast
apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('axios error', error);
    if (error instanceof AxiosError) {
      const errorMessage =
        error?.message ||
        error?.response?.data?.message ||
        'Something went wrong, Please try again later';
      toast.error(
        typeof errorMessage === 'string'
          ? errorMessage
          : 'Something went wrong, please try again later',
        {
          id: 'GLOBAL_ERROR',
        },
      );
    }

    return Promise.reject(error);
  },
);

export default axios;
